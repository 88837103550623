<template>
    <div id="Sign1">
        <div class="hadheight"></div>
        <Header />
        <div class="resetpas-sec1 base80">
            <div class="basecont">
                <div class="cont">
                    <div class="tit basefont30">重置密码</div>
                    <form action="" class="form">
                        <div class="item ">
                            <input type="password" v-model="form.password" placeholder="输入新密码" value="">
                            <div class="f_tishi" v-show="tishi">
                                <p>包含字母和数字</p>
                                <p>且长度为8-16个字符</p>
                            </div>
                        </div>
                        <div class="item ">
                            <input type="password" v-model="form.password2" placeholder="重复新密码" value="">
                        </div>
                        <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                            <span>确认设置</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <!-- 成功弹窗 -->
        <div class="resetpas_alt">
            <div class="bag" @click="afteFadeOut()"></div>
            <div class="cont">
                <a href="javascript:;" @click="afteFadeOut()" class="close" ><img src="../../assets/images/closeiconf1.png" alt=""></a>
                <div class="f_iocn">
                    <i><img src="../../assets/images/chenggongwfdagou1.png" alt=""></i>
                    <span>密码重置成功</span>
                </div>
                <div class="text">{{seh}}s后跳入登录页面</div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: "Resetpas",
    components:{
        Header,
        Footer
    },
    data(){
        return {
            form:{
                password: "",
                password2:"",
                
            },
            tishi: false,
            seh: 5, //重置密码成功后倒计时
        }
    },
    computed:{
        
    },
    methods:{
        afteFadeOut:function(){
            $(".resetpas_alt").fadeOut();
        },
        afteFadeIn:function(){
            $(".resetpas_alt").fadeIn();
            setInterval(()=>{
                this.seh--;
                if(this.seh<=0){
                    this.$router.push({path:"/sign/passign"});
                }
            },1000);
        },
        formSubmit:function(){
            var pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;//8到16位数字与字母组合
            if(this.form.password == ""){
                this.$message({
                    message: '密码不能为空',
                    type: 'error',
                });
                return false;
            }
            if(!pwdReg.test(this.form.password)){
                this.tishi = true;
                setTimeout(()=>{this.tishi=false},2000);
                return false;
            }
            if(this.form.password!=this.form.password2){
                this.$message({
                    message: '两次密码不一致',
                    type: 'error',
                });
                return false;
            }
            this.afteFadeIn();
        }
    }
}
</script>
